<template>
  <div id="app">
    <Top />
    <div v-if="$route.path!=='/'" class="app-fixed-tool text-center">
      <div class="p-v-10 item" id="miniProgramRightItem" @mouseover="Show" @mouseout="Show">
          <i class="f-23 icon icon-mini_program"></i>
          <div class="text f-11">小程序</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
          <i class="f-23 icon icon-trademark"></i>
          <div class="text f-11">商标咨询</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
          <i class="f-23 icon icon-company"></i>
          <div class="text f-11">公司注册</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
          <i class="f-23 icon icon-qualification"></i>
          <div class="text f-11">资质咨询</div>
      </div>
      <div class="p-v-10 item" @click="openDialog">
          <i class="f-23 icon icon-claim"></i>
          <div class="text f-11">认证咨询</div>
      </div>
      <div class="p-v-10 item toTop" @click="scrollToTop">
          <i class="f-23 icon icon-to_top"></i>
          <div class="text f-11">置顶</div>
      </div>
    </div>
    <!--小程序二维码-->
<div class="ant-popover ant-popover-placement-left" id="miniProgramQrCode" style="width: max-content;top:unset;left:unset;position: fixed;right: 80px; bottom: 360px;" :style="{ display: isShow?'block':'none'}">
  <div class="ant-popover-content">
      <div class="ant-popover-arrow"></div>
      <div role="tooltip" class="ant-popover-inner">
          <div>
              <div class="ant-popover-inner-content">
                  <div data-v-327b80f5="">
                      <img data-v-327b80f5="" src="@/assets/img/d957cbc6f8397067e21eb2fa585671af.png" alt="mini-program" class="mini-program">
                      <div data-v-327b80f5="" class="text-center m-t-15">创业小鸟-全球注册，全国通办</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
    <router-view/>
     <!-- 弹窗内容 -->
     <GlobalDialog ref="globalDialog" />
  </div>
</template>

<script>
  import Top from './components/top/index.vue';
  import AppDialog from "./components/appDialog";
  export default{
    components:{
      Top,
      AppDialog
    },
    data() {
      return {
        dialogVisible: false,
        isShow:false
      }
    },
    methods: {
      scrollToTop() {
      // 使用$refs获取DOM元素的引用
      const element = this.$refs.topElement;

      // 使用window对象的scrollTo方法来滚动到元素的顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 可选的，定义滚动行为，'smooth' 为平滑滚动
      });
    },
    openDialog() {
      this.$refs.globalDialog.dialogVisible = true;
    },
    Show(){
      this.isShow=!this.isShow
    }
    },
  }
</script>

<style lang="scss">

</style>
